import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface ReconcileState {
    erpSearch: string | undefined;
    erpDebitSearch: string | undefined;
    erpCreditSearch: string | undefined;
    bankSearch: string | undefined;
}

const initialState: ReconcileState = {
    erpSearch: undefined,
    erpDebitSearch: undefined,
    erpCreditSearch: undefined,
    bankSearch: undefined,

};


const reconcileSlice = createSlice({
    name : 'reconcile',
    initialState,
    reducers : {
        setErpSearch : (state, action : PayloadAction<string | undefined>) => {
            state.erpSearch = action.payload;
        },
        setErpDebitSearch : (state, action : PayloadAction<string | undefined>) => {
            state.erpDebitSearch = action.payload;
        },
        setErpCreditSearch : (state, action : PayloadAction<string | undefined>) => {
            state.erpCreditSearch = action.payload;
        },
        setBankSearch : (state, action : PayloadAction<string | undefined>) => {
            state.bankSearch = action.payload;
        }
    }
});
export default reconcileSlice.reducer;


export const {setErpSearch, setBankSearch, setErpDebitSearch, setErpCreditSearch} = reconcileSlice.actions;
export const selectErpSearch = (state : RootState) => state.reconcile.erpSearch;
export const selectErpDebitSearch = (state : RootState) => state.reconcile.erpDebitSearch;
export const selectErpCreditSearch = (state : RootState) => state.reconcile.erpCreditSearch;
export const selectBankSearch = (state : RootState) => state.reconcile.bankSearch;
import { Action, configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { ThunkAction } from 'redux-thunk';
import activateAccountsReducer from './reducers/activateAccountsSlice';
import alertsReducer from './reducers/alertsSlice';
import connectionReducer from './reducers/agreementSlice';
import fileImportReducer from './reducers/fileImportSlice';
import reconcileApi from './reducers/reconcileApi/reconcileApi';
import reconcileGroupReducer from './reducers/reconcileGroupSlice';
import reportsReducer from './reducers/reportsSlice';
import selectedAccountsReducer from './reducers/selectedAccountsSlice';
import themeReducer from './reducers/themeSlice';
import transactionsReducer from './reducers/transactionsSlice';
import userReducer from './reducers/userSlice';
import searchReducer from './reducers/searchSlice';
import columnVisibilityReducer from './reducers/columnVisibilitySlice';
import bookkeepingReducer from './reducers/bookkeepingSlice';
import reconcileReducer from './reducers/reconcileSlice'
import dialogReducer from './reducers/dialogSlice';
import { signalRMiddleware } from '../services/signalRMiddleware';

export const store = configureStore({
  reducer: {
    user: userReducer,

    selectedAccounts: selectedAccountsReducer,
    transactions: transactionsReducer,
    columnVisibility: columnVisibilityReducer,
    reconcileGroup: reconcileGroupReducer,
    reports: reportsReducer,
    activateAccounts: activateAccountsReducer,
    alerts: alertsReducer,
    theme: themeReducer,
    connection: connectionReducer,
    fileImport: fileImportReducer,
    bookkeeping : bookkeepingReducer,
    search : searchReducer,
    reconcile : reconcileReducer,
    dialog : dialogReducer,
    [reconcileApi.reducerPath]: reconcileApi.reducer,
  },
  middleware: (gDM) => gDM().concat(reconcileApi.middleware).concat(signalRMiddleware)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()

export default store;
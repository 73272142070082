import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const LOCAL_STORAGE_KEYS = {
  erp: "user_selected_columns_erp",
  bank: "user_selected_columns_bank"
};

interface ColumnState {
  erp: string[] | null;
  bank: string[] | null;
}

const loadFromLocalStorage = (key: string): string[] | null => {
  try {
    return JSON.parse(localStorage.getItem(key) || "null");
  } catch {
    return null;
  }
};

const initialState: ColumnState = {
  erp: loadFromLocalStorage(LOCAL_STORAGE_KEYS.erp),
  bank: loadFromLocalStorage(LOCAL_STORAGE_KEYS.bank),
};

const columnVisibilitySlice = createSlice({
  name: 'columnVisibility',
  initialState,
  reducers: {
    setVisibleColumns: (state, action: PayloadAction<{ tableType: 'erp' | 'bank'; columns: string[] }>) => {
      const { tableType, columns } = action.payload;
      state[tableType] = columns;
      localStorage.setItem(LOCAL_STORAGE_KEYS[tableType], JSON.stringify(columns));
    },
  },
});

export const { setVisibleColumns } = columnVisibilitySlice.actions;
export default columnVisibilitySlice.reducer;

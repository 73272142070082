// transactionsSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BankTransaction, ErpTransaction } from '../models/interfaces/Transaction';
import TransactionDateFilter from '../models/interfaces/TransactionDateFilter';
import { RootState } from '../store';

interface TransactionsState {
  selectedTransaction: ErpTransaction | BankTransaction | undefined;
  selectedErpTransactions: ErpTransaction[];
  selectedBankTransactions: BankTransaction[];
  transactionDateFilter: TransactionDateFilter;
  isOpenTransactions: boolean;
  // New properties for interim split view:
  selectedInterimPositiveErpTransactions: ErpTransaction[];
  selectedInterimNegativeErpTransactions: ErpTransaction[];
}

const initialState: TransactionsState = {
  selectedTransaction: undefined,
  selectedErpTransactions: [] as ErpTransaction[],
  selectedBankTransactions: [] as BankTransaction[],
  transactionDateFilter: { from: undefined, to: undefined } as TransactionDateFilter,
  isOpenTransactions: true,
  selectedInterimPositiveErpTransactions: [] as ErpTransaction[],
  selectedInterimNegativeErpTransactions: [] as ErpTransaction[],
};

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    setSelectedTransaction: (state, action: PayloadAction<ErpTransaction | BankTransaction | undefined>) => {
      state.selectedTransaction = action.payload;
    },
    setSelectedErpTransactions: (state, action: PayloadAction<ErpTransaction[]>) => {
      state.selectedErpTransactions = action.payload;
    },
    setSelectedBankTransactions: (state, action: PayloadAction<BankTransaction[]>) => {
      state.selectedBankTransactions = action.payload;
    },
    setTransactionDateFilter: (state, action: PayloadAction<TransactionDateFilter>) => {
      state.transactionDateFilter = action.payload;
    },
    setIsOpenTransactions: (state, action: PayloadAction<boolean>) => {
      state.isOpenTransactions = action.payload;
    },
    setSelectedInterimPositiveErpTransactions: (state, action: PayloadAction<ErpTransaction[]>) => {
      state.selectedInterimPositiveErpTransactions = action.payload;
    },
    setSelectedInterimNegativeErpTransactions: (state, action: PayloadAction<ErpTransaction[]>) => {
      state.selectedInterimNegativeErpTransactions = action.payload;
    },
  }
});

export const {
  setSelectedTransaction,
  setSelectedErpTransactions,
  setSelectedBankTransactions,
  setTransactionDateFilter,
  setIsOpenTransactions,
  setSelectedInterimPositiveErpTransactions,
  setSelectedInterimNegativeErpTransactions,
} = transactionsSlice.actions;

// Existing selectors
export const selectSelectedTransaction = (state: RootState) => state.transactions.selectedTransaction;
export const selectSelectedErpTransactions = (state: RootState) => state.transactions.selectedErpTransactions;
export const selectSelectedBankTransactions = (state: RootState) => state.transactions.selectedBankTransactions;
export const selectTransactionDateFilter = (state: RootState) => state.transactions.transactionDateFilter;
export const selectIsOpenTransactions = (state: RootState) => state.transactions.isOpenTransactions;
export const selectSelectedInterimPositiveErpTransactions = (state: RootState) =>
  state.transactions.selectedInterimPositiveErpTransactions;
export const selectSelectedInterimNegativeErpTransactions = (state: RootState) =>
  state.transactions.selectedInterimNegativeErpTransactions;

export default transactionsSlice.reducer;

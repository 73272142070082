import { adaptV4Theme, createTheme } from '@mui/material/styles';
import "../styles/css/roboto.css";

const theme = createTheme(adaptV4Theme({
  typography: {
    fontFamily: [
      "Roboto",
      "Tahoma",
      "Verdana",
      "Helvetica",
      "Arial",
      "sans-serif",
    ].join(","),
    fontSize: 12,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  palette: {
    mode: 'dark',
    primary: {
      main: "#4BB0F7",
      light: "#eee",
      contrastText: "rgba(255, 255, 255, 0.95)",
    },
    secondary: {
      main: '#1b2430',
      light: '#121921',
      // main: "#FFB752",
      // light: "#FFB752",
      contrastText: "rgba(255, 255, 255, 0.95)"
    },
    primaryHome: {
      main: '#FAFAFA',
      light: '#eee',
      contrastText: '#303030'
    },
    secondaryHome: {
      main: '#FFB752',
      light: '#FFB752'
    },
    third: {
      main: "#888",
      light: "#ddd",
    },
    fourth: {
      main: "#1b2430",
    },
    fifth: {
      main: "#4339f2",
    },
    six: {
      main: "rgba(255, 255, 255, 0.12)",
      light: "rgba(255, 255, 255, 0.95)",
    },
    gradient: {
      main: "#1b2430",
      dark: "#121921",
    },
    text: {
      // primary: "#E7E5EE",
      primary: "#FBFFFF",
      secondary: "#9EA4A8",
      // secondary: "#C1BCD3",
      hint: '#8e9294',
      disabled: 'rgba(255, 255, 255, 0.10)'
    },
    topBar: {
      main: "#1b2430",
      contrastText: "#fff",
    },
    widget: {
      main: 'rgba(255, 255, 255, 0.10)',
      dark: "#e1e1e1",
      contrastText: "#fff",
    },
    error: {
      main: "#ff4b28",
      dark: "#d5391d",
      contrastText: "#fff",
    },
    background: {
      default: "#181E23",
      paper: "#1b2430"
      // default: "#303030",
    },
    warning: {
      main: "#FF8C00",
    },
    information: {
      main: '#ace1ff57',
      light: '#e6f6ff',
    },
    divider: "#3c5678",
  },
  overrides: {
    MuiListItemIcon: {
      root: {
        color: "rgba(255, 255, 255, 0.95)",
        minWidth: 44,
      },
    },
    MuiListItem: {
      button: {
        paddingTop: 6,
        paddingBottom: 6,
      },
      root: {
        color: 'rgba(255, 255, 255, 0.95)',
      },
    },
    MuiListSubheader: {
      inset: {
        paddingLeft: 60,
      },
    },
    MuiStepper: {
      root: {
        background: "none",
        border: "none",
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        color: '#FBFFFF',
        '&$checked': {
          color: '#4BB0F7',
        },
      },
    },
    MuiCssBaseline: {
      "@global": {
        "*::-webkit-scrollbar": {
          width: "0.4em",
        },
        "*::-webkit-scrollbar-track": {
          "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "*::-webkit-scrollbar-thumb": {
          backgroundColor: "rgba(0,0,0,.3)",
          outline: "1px solid slategrey",
          borderRadius: 2,
        },
      },
    },
  },
} as any));
theme.shadows[1] = theme.shadows[4];
export default theme;
